export enum AriaRoles {
  // Document structure roles
  ARTICLE = "article",
  BANNER = "banner",
  HEADING = "heading",
  MAIN = "main",
  NAVIGATION = "navigation",

  // Landmark roles
  DIALOG = "dialog",
  STATUS = "status",
  ALERT = "alert",

  // Widget roles
  BUTTON = "button",
  LINK = "link",
  MENUITEM = "menuitem",
  IMG = "img",
  FEED = "feed",
  GRID = "grid",
  MENU = "menu",
  MENUBAR = "menubar",
  TEXTBOX = "textbox",

  // Grouping roles
  GROUP = "group",
  LIST = "list",
  LISTITEM = "listitem",

  // Separator roles
  SEPARATOR = "separator",

  // Presentation role
  PRESENTATION = "presentation", // Indicates content is decorative
  NONE = "none", // Same as "presentation"
}

export enum AriaCurrent {
  PAGE = "page",
  DATE = "date",
}

export const AppAccessibilityLabel = {
  INSTALL_BROWSER_EXTENSION: "Install the Yahoo Shopping Extension for Chrome",
};
