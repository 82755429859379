"use client";

import { type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import { GenericPromoBanner } from "@/components/promoBanner/bannerTypes/GenericPromoBanner";
import { useShoppingExtension } from "@/hooks/useShoppingExtension";
import { type AnnouncementBanner } from "@/types/Experience";

interface ShoppingExtPromoProps {
  bannerData: AnnouncementBanner;
  className?: string;
  isChromeDesktop: boolean;
  dataYlk?: YlkData;
}

export const ShoppingExtPromo: FC<ShoppingExtPromoProps> = ({
  isChromeDesktop = false,
  className = "",
  dataYlk = {},
  bannerData,
}) => {
  const handleClose = () => {
    disablePromoBannerInStorage();
  };

  const { disablePromoBannerInStorage, showPromo, showPromoUserPreference } =
    useShoppingExtension({ isChromeDesktop });

  if (!isChromeDesktop || showPromoUserPreference === false || !showPromo) {
    return <></>;
  } else {
    return (
      <GenericPromoBanner
        bannerData={bannerData}
        className={className}
        dataYlk={dataYlk}
        extendOnClose={handleClose}
      />
    );
  }
};
