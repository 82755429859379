"use client";

import { Box, Icon, Text } from "@yahoo/uds";
import { CrossCircle } from "@yahoo/uds-icons";
import {
  getDataYlkString,
  I13nAnchor,
  I13nButton,
  type I13NSec,
  useRapid,
  type YlkData,
} from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { useState, type FC } from "react";
import { promoBannerIconMap } from "@/configs/promoBanner";
import { type AnnouncementBanner } from "@/types/Experience";

interface PromoBannerProps {
  bannerData: AnnouncementBanner;
  className?: string;
  dataYlk?: YlkData;
  isExtensionPromo?: boolean;
  extendOnClose?: (e?: any) => void;
}

export const GenericPromoBanner: FC<PromoBannerProps> = ({
  bannerData,
  className = "",
  dataYlk = {},
  extendOnClose = () => {},
}) => {
  const sec: I13NSec = "promo-banner";

  const [showPromo, setShowPromo] = useState(true);
  const { mpos } = useRapid(sec);
  const IconComponent = bannerData?.icon
    ? promoBannerIconMap[bannerData.icon]
    : null;

  const onClose = (e: any) => {
    e.preventDefault();
    setShowPromo(false);
    extendOnClose(e);
  };

  if (!showPromo) return null;

  return (
    <Box
      id={sec}
      className={classNames(
        "bg-brand p-2.5 md:flex lg:mx-8 lg:rounded-md",
        className,
      )}
      data-ylk={getDataYlkString({
        itc: 0,
        mpos,
        sec,
        ...dataYlk,
      })}
    >
      <Box className="grow items-center justify-center">
        {!!IconComponent && <IconComponent className="mr-1 hidden sm:block" />}
        <Text className="font-centra !text-sm text-white md:!text-[15px]">
          <span className="pr-1">{bannerData.description}</span>
          {bannerData.ctaText && bannerData.ctaUrl && (
            <I13nAnchor
              href={bannerData.ctaUrl}
              dataYlk={{
                elm: "hdln",
                slk: bannerData.ctaText,
              }}
              className="inline-block text-white underline"
              target="_blank"
            >
              {bannerData.ctaText}
            </I13nAnchor>
          )}
        </Text>
      </Box>
      {bannerData.hasCloseButton && (
        <I13nButton
          aria-label="Close promotion banner"
          dataYlk={{
            elm: "close",
            slk: "bar-close",
          }}
          onClick={onClose}
        >
          <Icon
            name={CrossCircle}
            color="white"
            variant="fill"
            className="cursor-pointer"
          />
        </I13nButton>
      )}
    </Box>
  );
};
