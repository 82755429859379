"use client";

import {
  createContext,
  type PropsWithChildren,
  useContext,
  type FC,
} from "react";
import { type AnnouncementBanner } from "@/types/Experience";

export const AnnouncementBannerContext = createContext<
  AnnouncementBanner | undefined
>(undefined);

export const AnnouncementBannerProvider: FC<
  PropsWithChildren<{
    announcementBannerContext: AnnouncementBanner | undefined;
  }>
> = ({ children, announcementBannerContext }) => {
  return (
    <AnnouncementBannerContext.Provider value={announcementBannerContext}>
      {children}
    </AnnouncementBannerContext.Provider>
  );
};

export const useAnnouncementBannerContext = () =>
  useContext(AnnouncementBannerContext);
