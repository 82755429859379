"use client";

import { type YlkData } from "@yahoo-commerce/i13n";
import { getPathWithoutPage } from "@yahoo-commerce/util";
import { usePathname } from "next/navigation";
import { type FC } from "react";
import { GenericPromoBanner } from "@/components/promoBanner/bannerTypes/GenericPromoBanner";
import { ShoppingExtPromo } from "@/components/promoBanner/bannerTypes/ShoppingExtension";
import { useAnnouncementBannerContext } from "@/contexts/AnnouncementBannerContext";
import { useRequestContext } from "@/lib/request/client";
import { type AnnouncementBanner } from "@/types/Experience";
import { buildUrlArrays, evaluateBannerPaths } from "@/utils/promoBanner";

interface PromoBannerProps {
  className?: string;
  dataYlk?: YlkData;
  isChromeDesktop: boolean;
}

/**
 * Renders the promo banner based on the banner type.
 */
export const PromoBanner: FC<PromoBannerProps> = ({
  className,
  dataYlk,
  isChromeDesktop,
}) => {
  const bannerData = useAnnouncementBannerContext();
  const pathname = usePathname();
  const { site } = useRequestContext();

  const shouldShowBanner = evaluateBannerPaths(
    getPathWithoutPage(pathname), // Strip pagination from the path
    buildUrlArrays(bannerData?.includedPageUrls),
    buildUrlArrays(bannerData?.excludedPageUrls),
    site,
  );

  // shouldShowBanner should always turn off the banner if it's set to false
  if (!shouldShowBanner) {
    return null;
  }
  // If we should show the banner but the text is missing, don't render the banner
  if (!bannerData?.ctaText) {
    return null;
  }

  switch (bannerData?.bannerType) {
    case "browser-extension":
      return (
        <ShoppingExtPromo
          bannerData={bannerData}
          className={className}
          dataYlk={dataYlk}
          isChromeDesktop={isChromeDesktop}
        />
      );

    default:
      return (
        <GenericPromoBanner
          bannerData={bannerData ?? ({} as AnnouncementBanner)}
          className={className}
          dataYlk={dataYlk}
        />
      );
  }
};
