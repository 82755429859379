"use client";

import { debounce } from "@yahoo-commerce/util";
import { useEffect, useState, type MutableRefObject } from "react";

export const useContentHeight = (ref: MutableRefObject<Element | null>) => {
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setContentHeight(Math.floor(ref.current.clientHeight));

    const observer = new ResizeObserver(
      debounce(([entry]) => {
        const height =
          entry.borderBoxSize?.[0]?.blockSize ?? entry.contentRect.height;

        setContentHeight(Math.floor(height));
      }, 300),
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return contentHeight;
};
