import { getUrlObject } from "@yahoo-commerce/util";
import { type PageUrl } from "@/types/Experience";

/**
 * Build an array of urls from an array of PageUrl objects.
 *
 * @param {PageUrl[]} urls the array of PageUrl objects
 * @returns the array of urls
 */
const buildUrlArrays = (urls: PageUrl[] | undefined): string[] => {
  return urls?.map((url) => url.url) || [];
};

/**
 * Test if the current path matches the given array of paths.
 * If the path includes the wildcard "*"", use a regex to test it.
 *
 * @param {string} currentPath the current path
 * @param {string[]} urls the included or excluded paths
 * @param {string} site the current site
 * @returns boolean
 */
const testPaths = (currentPath: string, urls: string[], site: string) => {
  return urls.some((url) => {
    const urlObj = getUrlObject({ site, url });
    const pathname = urlObj?.pathname;
    // Check if the pathname contains the wildcard character
    if (pathname?.includes("*")) {
      const regex = new RegExp(
        pathname.replace(/\*/g, ".*").replace(/\//g, "\\/"),
      );
      return regex.test(currentPath);
    }

    return pathname === currentPath;
  });
};

/**
 * Evaluate the included and excluded paths to determine if the banner should be shown.
 * If there are no included or excluded paths, show the banner on all pages.
 * Included paths are preferred over excluded paths.
 *
 * @param {string} currentPath the current path
 * @param {string[]} includedUrls the included paths
 * @param {string[]} excludedUrls the excluded paths
 * @param {string} site the current site
 * @returns boolean
 */
const evaluateBannerPaths = (
  currentPath: string,
  includedUrls: string[],
  excludedUrls: string[],
  site: string,
) => {
  // If there are no included or excluded paths, show the banner on all pages
  if (includedUrls?.length === 0 && excludedUrls?.length === 0) {
    return true;
  }

  // Prefer included paths over excluded paths
  if (includedUrls?.length > 0) {
    return testPaths(currentPath, includedUrls, site);
  } else if (excludedUrls?.length > 0) {
    return !testPaths(currentPath, excludedUrls, site);
  }

  return false;
};

export { buildUrlArrays, evaluateBannerPaths, testPaths };
