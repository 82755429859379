"use client";

import {
  buildResponsiveAdProps,
  ResponsiveAd,
  type AdLoadingMode,
  type Size,
} from "@yahoo-commerce/monetization";
import { SCREEN_WIDTH, times } from "@yahoo-commerce/util";
import classNames from "classnames";
import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type MutableRefObject,
  type FC,
} from "react";
import { useContentHeight } from "@/hooks/useContentHeight";
import { useRequestContext } from "@/lib/request/client";

export interface RightRailAdLocation {
  location: string;
  kvs?: Record<string, string>;
}

const DEFAULT_ALLOWED_AD_SIZES: Size[] = [
  { height: 250, width: 300 },
  { height: 600, width: 300 },
];

const paddingBottom = 40;
const firstBottomSpacing = 300; // only 300px of space between the first 2 ads
const restBottomSpacing = 500; // 500px of space between the rest of the ads

export const getBottomSpacing = (index: number) =>
  index === 0 ? firstBottomSpacing : restBottomSpacing;

const getMarginBottom = (index: number) =>
  getBottomSpacing(index) - paddingBottom;
const getPreviousMarginBottom = (index: number) =>
  index === 0 ? 0 : getMarginBottom(index - 1);
const getPreviousBottomSpacing = (index: number) =>
  index === 0 ? 0 : getPreviousMarginBottom(index) + paddingBottom;

export const calculateAdCount = ({
  ads: adHeights,
  container: containerHeight,
  marginTop = 0,
  maxAdCount,
  maxAdHeight,
}: {
  ads: number[];
  container: number;
  marginTop?: number;
  maxAdCount: number;
  maxAdHeight: number;
}): number => {
  const getSpaceNeededForAd = (index: number) =>
    (index === 0 ? marginTop : 0) + // top spacing
    (adHeights[index] ?? maxAdHeight) +
    getPreviousBottomSpacing(index); // bottom spacing

  let adCount = 0;
  let totalAdHeight = 0;
  let spaceNeededForNextAd = getSpaceNeededForAd(0);

  while (containerHeight - totalAdHeight >= spaceNeededForNextAd) {
    totalAdHeight += spaceNeededForNextAd;
    adCount++;

    spaceNeededForNextAd = getSpaceNeededForAd(adCount);
  }

  return Math.min(adCount, maxAdCount);
};

const getKvsForIndex = (
  adIndex: number,
  kvs?: Record<string, string>,
): Record<string, string> | undefined => {
  if (!kvs || !kvs.loc) {
    return kvs;
  }
  const indexSuffix = adIndex === 0 ? "" : `_${adIndex + 1}`;
  return { ...kvs, loc: `${kvs.loc}${indexSuffix}` };
};

export const getLocation = (
  locations: RightRailAdLocation[],
  adIndex: number,
): {
  kvs?: Record<string, string>;
  location: string;
  locationNumber: number;
} => {
  const numPositions = locations.length;
  if (adIndex < numPositions) {
    return {
      kvs: getKvsForIndex(adIndex, locations[adIndex].kvs),
      location: locations[adIndex].location,
      locationNumber: 1,
    };
  } else {
    return {
      kvs: getKvsForIndex(adIndex, locations[numPositions - 1].kvs),
      location: locations[numPositions - 1].location,
      locationNumber: adIndex - numPositions + 2,
    };
  }
};

interface AdProps {
  adCount: number;
  allowedAdSizes?: Size[];
  index: number;
  kvs?: Record<string, string>;
  loading?: AdLoadingMode;
  location: string;
  locationNumber: number;
  marginTop: number;
  maxAdHeight: number;
  minScreenSize?: keyof typeof SCREEN_WIDTH;
  onResize: (props: { index: number; size: Size }) => void;
  placement?: "main" | "ros";
  subnavRendered?: boolean;
}

// this component is memoized to prevent unnecessary rerenders of the underlying ad
const RightRailAd: FC<AdProps> = memo(
  ({
    adCount,
    allowedAdSizes = DEFAULT_ALLOWED_AD_SIZES,
    index,
    kvs,
    loading,
    location,
    locationNumber,
    marginTop,
    maxAdHeight,
    minScreenSize = "md",
    onResize: onIndexedAdResize,
    placement = "ros",
    subnavRendered,
  }) => {
    const lastAd = index === adCount - 1;
    const configs = buildResponsiveAdProps([
      {
        device: "dt",
        screenWidth: { from: SCREEN_WIDTH[minScreenSize] },
        sizes: allowedAdSizes,
      },
    ]);

    const [adHeight, setAdHeight] = useState<number>(maxAdHeight);
    const adContainerHeight = adHeight + getBottomSpacing(index);
    const { site, webview } = useRequestContext();

    const onResize = useCallback(
      (size: Size) => {
        onIndexedAdResize({ index, size });
        setAdHeight(size.height);
      },
      [index, onIndexedAdResize],
    );

    let className: string;
    let style: CSSProperties;
    if (lastAd) {
      // expand the last ad's container the fill the rail's remaining height
      className = "flex-auto";
      style = { marginTop };
    } else {
      // use a fixed height for ads' containers
      className = "flex-none";
      style = { height: adContainerHeight, marginTop, paddingBottom };
    }

    return (
      <div className={className} style={style}>
        <ResponsiveAd
          className={classNames(
            "sticky flex flex-none",
            webview
              ? subnavRendered
                ? "top-20"
                : "top-5"
              : subnavRendered
                ? "top-[135px]"
                : "top-[75px]",
          )}
          configs={configs}
          kvs={kvs}
          loading={loading}
          location={location}
          locationNumber={locationNumber}
          pageRegion="index"
          placement={placement}
          resizable
          onResize={onResize}
          site={site}
        />
      </div>
    );
  },
);
RightRailAd.displayName = "RightRailAd";

interface Props {
  allowedAdSizes?: Size[];
  className?: string;
  contentRef: MutableRefObject<HTMLElement | null>;
  loading?: AdLoadingMode;
  locations: RightRailAdLocation[];
  maxAdCount: number;
  marginTop?: number;
  minScreenSize?: keyof typeof SCREEN_WIDTH;
  placement?: "main" | "ros";
  subnavRendered?: boolean;
}

export const RightRailAds: FC<Props> = ({
  allowedAdSizes = DEFAULT_ALLOWED_AD_SIZES,
  className,
  contentRef,
  loading,
  locations,
  maxAdCount,
  marginTop = 0,
  minScreenSize = "md",
  placement = "ros",
  subnavRendered,
}) => {
  const maxAdWidth = Math.max(...allowedAdSizes.map(({ width }) => width));
  const adHeights = allowedAdSizes.map(({ height }) => height);
  const maxAdHeight = Math.max(...adHeights);

  const adHeightsRef = useRef<number[]>([maxAdHeight]);
  const [adCount, setAdCount] = useState(1);
  const contentHeight = useContentHeight(contentRef);

  const recalculateAdCount = useCallback(() => {
    const existingAdCount = adHeightsRef.current.length;
    const desiredAdCount = calculateAdCount({
      ads: adHeightsRef.current,
      container: contentHeight || 0,
      marginTop,
      maxAdCount,
      maxAdHeight,
    });

    if (existingAdCount === desiredAdCount) {
      return;
    }

    if (existingAdCount > desiredAdCount) {
      // shrink the list down to the desired count
      adHeightsRef.current = adHeightsRef.current.slice(0, desiredAdCount);
    } else if (existingAdCount < desiredAdCount) {
      // expand the list of ad heights up to the desired count, assuming the max height for each
      const adsToAdd = desiredAdCount - existingAdCount;
      for (let index = 0; index < adsToAdd; index++) {
        adHeightsRef.current.push(maxAdHeight);
      }
    }

    setAdCount(desiredAdCount);
  }, [contentHeight, marginTop, maxAdCount, maxAdHeight]);

  const onAdResize = useCallback(
    ({ index, size: { height } }: { index: number; size: Size }) => {
      // recalculate the ad count on ad resize
      adHeightsRef.current[index] = height;
      recalculateAdCount();
    },
    [recalculateAdCount],
  );

  useEffect(() => {
    // calculate the initial ad count whenever recalculateAdCount is redefined
    // (e.g. when the contentHeight changes)
    recalculateAdCount();
  }, [recalculateAdCount]);

  return (
    <aside
      className={classNames(
        "ml-8 hidden flex-none",
        `${minScreenSize}:block`,
        className,
      )}
      style={{
        maxHeight: contentHeight || maxAdHeight,
        minHeight: maxAdHeight,
        width: maxAdWidth,
      }}
    >
      <div className="flex size-full flex-col flex-nowrap">
        {times(adCount, (index) => {
          const { kvs, location, locationNumber } = getLocation(
            locations,
            index,
          );
          return (
            <RightRailAd
              adCount={adCount}
              allowedAdSizes={allowedAdSizes}
              index={index}
              key={index}
              kvs={kvs}
              loading={loading}
              location={location}
              locationNumber={locationNumber}
              marginTop={
                index === 0 && contentHeight > marginTop + maxAdHeight
                  ? marginTop
                  : 0
              }
              maxAdHeight={maxAdHeight}
              onResize={onAdResize}
              placement={placement}
              subnavRendered={subnavRendered}
            />
          );
        })}
      </div>
    </aside>
  );
};
