import { useEffect, useState } from "react";

interface UsePromoStateProps {
  isChromeDesktop: boolean;
}

const promoBannerStorageKey = "shoppingExtPromo";
export const useShoppingExtension = ({
  isChromeDesktop,
}: UsePromoStateProps) => {
  const shoppingExtensionId =
    process.env.NEXT_PUBLIC_CHROME_SHOPPING_EXTENSION_ID || "";
  const [showPromo, setShowPromo] = useState(false);
  const [showPromoUserPreference, setShowPromoUserPreference] = useState<
    boolean | undefined
  >(undefined);
  const disablePromoBannerInStorage = () => {
    localStorage.setItem(promoBannerStorageKey, "false");
  };

  useEffect(() => {
    const savedState = localStorage.getItem(promoBannerStorageKey);
    if (savedState === null) {
      const shouldShowPromo = isChromeDesktop && showPromo;
      setShowPromo(shouldShowPromo);
    } else {
      setShowPromoUserPreference(savedState === "true");
      setShowPromo(savedState === "true");
    }

    if (typeof window === "undefined" || !shoppingExtensionId) return;
    if (typeof chrome !== "undefined" && chrome?.runtime?.sendMessage) {
      chrome.runtime.sendMessage(
        shoppingExtensionId,
        { type: "VALIDATE_EXTENSION" },
        (response) => {
          if (response?.success) {
            setShowPromo(false);
            disablePromoBannerInStorage();
          } else {
            setShowPromo(true);
          }
        },
      );
    } else {
      setShowPromo(true);
    }
  }, [
    isChromeDesktop,
    shoppingExtensionId,
    showPromo,
    showPromoUserPreference,
  ]);

  return {
    disablePromoBannerInStorage,
    setShowPromo,
    showPromo,
    showPromoUserPreference,
  };
};
